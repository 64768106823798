
import React from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';

// import assets
import IconTriangle from '../../assets/icons/triangle'

class Tooltip extends React.Component {
    constructor(props) {
        super(props);
        this.tooltipRef = React.createRef()
    }

    render() {
        if (this.props.className && this.tooltipRef && this.tooltipRef.current && (window.innerWidth > 1024)) {

            if (((window.innerWidth - this.tooltipRef.current.getBoundingClientRect().right) < 191)) {
                this.tooltipRef.current.classList.add('right-edge')
            } else if (this.tooltipRef.current.classList.contains('right-edge')) {
                this.tooltipRef.current.classList.remove('right-edge')
            }
        }
        return (
            <div className="tooltip" ref={this.tooltipRef}>
                {this.props.children}
                
                
                    <div className={`tooltip__container ${this.props.className ? this.props.className : ''}`}>
                        {this.props.perfectscroll ?
                            <PerfectScrollbar>
                                {this.props.text}
                            </PerfectScrollbar>
                        
                        :    <>{this.props.text}</>}

                        {window.innerWidth > 1024 && <IconTriangle/>}
                    </div>
               
            </div>
        );
    }
}

export default Tooltip;