
import global from './global'
import moment from 'moment';

const api = {
    getStats(data) {
        // Starting the week on monday
        moment.updateLocale('en',{
            week: {dow: 1}
        })

        // Total tweets video
        let tweetsVideo = data.tweetsVideo,
        tweetsVideo_length = tweetsVideo.length,

        // All tweets per account
        userTweets = data.userTweets,

        // All tweets video per account
        userTweetsVideo = data.userTweetsVideo,

        total_accounts = 0,
        total_accounts_video = 0,

        total_languages = [],
        avg_video_duration = 0,
        avg_engagement = [],

        avg_video_ratios = [],
        avg_text_ratios = [],
        avg_photo_ratios = [],
        avg_gif_ratios = [],
        avg_link_ratios = [],

        avg_frequency_publication = [],
        avg = 0,
        sum = 0

        // Loop for stats : total_languages and avg_video_duration
        if (tweetsVideo_length) {
            for (let i = 0, j = tweetsVideo_length; i < j; i++) {
                if (!total_languages.includes(tweetsVideo[i].lang)) total_languages.push(tweetsVideo[i].lang)
                avg_video_duration += tweetsVideo[i].media_duration_ms
            }
    
            // Get the total time from millisecond
            avg_video_duration = global.getTimeFromMs(avg_video_duration/tweetsVideo_length)    
        }

        // Loop for stats : avg_engagement && avg_video_ratios && avg_frequency_publication
        for (let userTweetVideo in userTweetsVideo) {
            // Length of the total tweets for the current account
            let userTweetsLength = userTweets[`${userTweetVideo}`].length

            // Length of the total tweets VIDEO for the current account
            let tweetsVideoLength = userTweetsVideo[userTweetVideo].length

            if (tweetsVideoLength) {
                // Sum of engagement for current account
                sum = userTweetsVideo[userTweetVideo]
                .map(u => u.favorites + u.retweets)
                .reduce((a, b) => a + b, 0)

                // Avg of engagement for current account : sum of engagement DIVIDE by the number of tweets
                avg = sum ? Math.round(sum / tweetsVideoLength) : 0

                if (Number.isNaN(avg)) avg = 0

                // Add the avg engagement for the current in the list
                avg_engagement.push(avg)

               // Calculate avg_frequency_publication
                let tf = userTweetsVideo[userTweetVideo], array_freq = []

                // Filter by date
                tf = global.sortContent({tweets: tf, sort: 'Date'})

                for (let i = 0, j = tf.length; i < j; i++) {

                    if (!array_freq.length){
                        array_freq.push([tf[i].created_at])
                    } else {
                        if (moment(array_freq[array_freq.length - 1][0]).isSame(tf[i].created_at, 'week') ) array_freq[array_freq.length - 1].push(tf[i].created_at)
                        else array_freq.push([tf[i].created_at])
                    }
                }

                array_freq = array_freq.map(a => a.length)
                avg_frequency_publication.push(Math.round((array_freq.reduce((a, b) => a + b, 0) / array_freq.length)))

                // Increase the number of accounts having tweets video
                total_accounts_video++
            }

            if (userTweetsLength) {
                // Push video ratio for the current account
                avg_video_ratios.push(((tweetsVideoLength / userTweetsLength) *100))

                // Push text ratio for the current account
                avg_text_ratios.push((((userTweets[`${userTweetVideo}`].filter(t => t.type === 'text')).length / userTweetsLength) *100))

                // Push photo ratio for the current account
                avg_photo_ratios.push((((userTweets[`${userTweetVideo}`].filter(t => t.type === 'photo')).length / userTweetsLength) *100))

                // Push gif ratio for the current account
                avg_gif_ratios.push((((userTweets[`${userTweetVideo}`].filter(t => t.type === 'animated_gif')).length / userTweetsLength) *100))
                
                // Push link ratio for the current account
                avg_link_ratios.push((((userTweets[`${userTweetVideo}`].filter(t => t.type === 'link')).length / userTweetsLength) *100))

                // Increase the number of accounts having tweets 
                total_accounts ++
            }
        }
        // Calculate global avg of videos ratio
        avg_video_ratios = this.getAvg(avg_video_ratios, total_accounts)

        // Calculate global avg of text ratio
        avg_text_ratios = this.getAvg(avg_text_ratios, total_accounts)

        // Calculate global avg of photo ratio
        avg_photo_ratios = this.getAvg(avg_photo_ratios, total_accounts)

        // Calculate global avg of gif ratio
        avg_gif_ratios = this.getAvg(avg_gif_ratios, total_accounts)

        // Calculate global avg of link ratio
        avg_link_ratios = this.getAvg(avg_link_ratios, total_accounts)

        const sumRatios = avg_video_ratios + avg_text_ratios + avg_link_ratios + avg_photo_ratios + avg_gif_ratios 

        // Get 100 percent If the sum is not equal to 100 ,and the diff precision is equal to 0,01
        if (sumRatios !== 100 && (parseFloat(Math.abs(100 - sumRatios).toFixed(2)) === 0.01)) {
            const isHigher =  sumRatios > 100
            const min = 0.01
            let diff = isHigher ? (sumRatios - 100) * -1 : 100 - sumRatios
            diff = parseFloat(diff.toFixed(2))

            if (avg_video_ratios && !isHigher) avg_video_ratios = (avg_video_ratios + diff).toFixed(2)

            else if (avg_photo_ratios && !(isHigher && avg_photo_ratios === min)) avg_photo_ratios = (avg_photo_ratios + diff).toFixed(2)

            else if (avg_gif_ratios && !(isHigher && avg_gif_ratios === min)) avg_gif_ratios = (avg_gif_ratios + diff).toFixed(2)

            else if (avg_link_ratios && !(isHigher && avg_link_ratios === min)) avg_link_ratios = (avg_link_ratios + diff).toFixed(2)

            else if (avg_text_ratios && !(isHigher && avg_text_ratios === min)) avg_text_ratios = (avg_text_ratios + diff).toFixed(2)
        }

        // Calculate global avg of engagement
        if (avg_engagement.length) {
            // Global sum of engagement for all accounts
            sum = avg_engagement.reduce((a, b) => a + b, 0)

            // Global avg of engagement 
            avg_engagement = Math.round(sum / total_accounts_video)
        } else {
            avg_engagement = 0
        }

        // Calculate global avg frequency publication
        if (avg_frequency_publication.length) {
            // Global sum of videos ratio for all accounts
            sum = avg_frequency_publication.reduce((a, b) => a + b, 0)

            // Global avg of video ratios 
            avg_frequency_publication = (Math.round(sum / total_accounts_video))
        } else {
            avg_frequency_publication = 0
        }

        return {
            total_videos: tweetsVideo_length,
            total_languages: {number: total_languages.length, list: total_languages},
            avg_video_duration,
            avg_engagement,
            avg_video_ratios,
            avg_text_ratios,
            avg_photo_ratios,
            avg_gif_ratios,
            avg_link_ratios,
            avg_frequency_publication
        }
    },

    getTopHashtags(userTweetsVideo){
        if (!userTweetsVideo) return null

        let hashtags = {}
        let hashtags_array = []

        // Counting hashtags
        for (let userTweet in userTweetsVideo) {

            for (let i = 0, j = userTweetsVideo[userTweet].length; i < j; i++) {
                let t = userTweetsVideo[userTweet][i]

                if (global.isFilledArray(t.hashtags)) {
                    
                    // Array to store hashtags of the tweet, in order to not count multiple times a same hashtag
                    let userTweetHashtags = []

                    for (let h of JSON.parse(t.hashtags)) {
                        h = h.toLowerCase()
                        
                        if (!hashtags[h]) hashtags[h] = 1
                        else if (!userTweetHashtags.includes(h)) hashtags[h] = hashtags[h] + 1

                        userTweetHashtags.push(h)
                    }
                }
            }

        }

        // Add the hashtags in array to sort it
        for (let h in hashtags) {
            hashtags_array.push([h, hashtags[h]]);
        }

        // Sort the array
        hashtags_array.sort(function(a, b) {
            return b[1] - a[1] ;
        });
        
        // Return top 10
        if (hashtags_array.length > 10) {
            hashtags_array.length = 10
        }

        return hashtags_array
    },

    getAvg(list, total) {
        let sum = 0, avg = 0

        if (list.length) {
            // Global sum 
            sum = list.reduce((a, b) => a + b, 0)

            // Global avg 
            avg = sum / total
            avg = [0,100].includes(avg) ? avg : avg.toFixed(2)
        } 

        return parseFloat(avg)
    }
}

export default api;
