import React from 'react';
import { Switch, Route, Redirect} from 'react-router-dom'
import 'react-perfect-scrollbar/dist/css/styles.css';
import ReactGA from 'react-ga';

// Import components
import Dashboard from './components/dashboard/dashboard'

// Import assets
import logo from '../src/assets/logo.svg'

class App extends React.Component {
	componentDidMount() {
		// Set the favicon
		let linkIcon = document.querySelector("link[rel='icon']");
		linkIcon.setAttribute('href', logo)

		// If we have a tracking GA ID.
		if (process.env.REACT_APP_GA_ID) {

			// Init GA.
			this.GA = ReactGA;

			// Initialize GA.
			this.GA.initialize(process.env.REACT_APP_GA_ID);

			// Send page view.
			this.GA.pageview(window.location.pathname + window.location.search);
		}
	}

	render() {
		return (
			<div className="app">
				{/*********************/}
                    {/* LOADER */}
                {/********************/}
                <div className="loader"></div>

				<Switch>
					<Route exact path="/" component={Dashboard}/>
					<Route path="*">
						<Redirect to="/" />
					</Route>
				</Switch>
			</div> 
		);
	}
}

export default App;
