import React from 'react';

// Init component.
function IconTriangle(props) {
	return (
        <svg className="icon__triangle" width="16" height="10" viewBox="0 0 16 10" xmlns="http://www.w3.org/2000/svg">
            <path d="M6.38253 1.22402C7.18123 0.125816 8.81878 0.125816 9.61747 1.22402L16 10H0L6.38253 1.22402Z"/>
        </svg>
    );
}

export default IconTriangle;