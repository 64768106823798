import React from 'react';

// Init component.
function IconCheck(props) {
	return (
        <svg className="icon__check" width="10" height="9" viewBox="0 0 10 9" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M9.55273 1.56L3.67273 7.44L1 4.76727" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    );
}

export default IconCheck;