
import moment from 'moment';

const global = {

    // Get hours , minutes, seconds from milliseconds
    getTimeFromMs(duration){
        let total_seconds = duration && Math.floor((duration / 1000)),
        seconds = duration && Math.floor((duration / 1000) % 60),
        minutes = duration && Math.floor((duration / (1000 * 60)) % 60),
        hours = duration && Math.floor((duration / (1000 * 60 * 60)));

        let hours_display = hours ? (hours < 10 ? "0" + hours + ':' : hours + ':') : '';
        let minutes_display = minutes < 10 ? "0" + minutes + ':' : minutes + ':';
        let seconds_display = seconds < 10 ? "0" + seconds : seconds;
        
        return {
            display: `${hours_display}${minutes_display}${seconds_display}`,
            hours, minutes, seconds, total_seconds
        }
    },

    isFilledArray (array) {
        return array && JSON.parse(array) && Array.isArray(JSON.parse(array)) && JSON.parse(array).length ? JSON.parse(array) : null
    },

    sortContent(params) {
        let sort = params.sort,
        tweets = params.tweets

        // Date
        if (sort === 'Date') {
            tweets.sort(function(a, b) {
                return moment(b.created_at) - moment(a.created_at)
            });

        // Retweets
        } else if (sort === 'Retweets') {
            tweets.sort(function(a, b) {
                return b.retweets - a.retweets
            });
        
        // Likes
        } else if (sort === 'Likes') {
            tweets.sort(function(a, b) {
                return b.favorites - a.favorites
            });
        }
        return tweets
    },

    filterRetweetAndQuote(userTweets) {
        for (let userTweet in userTweets) {
            // Filter retweet
            userTweets[userTweet] = userTweets[userTweet].filter(userTweet => (userTweet.retweet !== 1) && (userTweet.quote !== 1))
        }
        return userTweets
    },

    getUserTweetsVideo(userTweets) {
        for (let userTweet in userTweets) {
            // Get only videos
            userTweets[userTweet] = userTweets[userTweet].filter(userTweet => userTweet.type === 'video')
        }
        return userTweets
    },

    getOldestVideoDate(tweets) {
        tweets = this.sortContent({tweets, sort: 'Date'})

        return tweets && tweets.length ? tweets[tweets.length - 1].created_at : null
    }
}

export default global;
