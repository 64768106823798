import React from 'react';

// Init component.
function IconDownload(props) {
	return (
        <svg className="icon__download" width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M14 9.66667V12.5556C14 12.9386 13.8478 13.306 13.5769 13.5769C13.306 13.8478 12.9386 14 12.5556 14H2.44444C2.06135 14 1.69395 13.8478 1.42307 13.5769C1.15218 13.306 1 12.9386 1 12.5556V9.66667" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M3.88889 6.05556L7.5 9.66667L11.1111 6.05556" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M7.5 9.66667V1" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    );
}

export default IconDownload;