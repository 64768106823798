import React from 'react';

// Init component.
function IconPlay(props) {
	return (
        <svg className="icon__play" width="29" height="32" viewBox="0 0 29 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g filter="url(#filter0_d)">
            <path d="M24 10.2679C25.3333 11.0378 25.3333 12.9623 24 13.7321L7.5 23.2583C6.16666 24.0281 4.5 23.0659 4.5 21.5263L4.5 2.47372C4.5 0.934117 6.16667 -0.0281317 7.5 0.741669L24 10.2679Z" fill="white"/>
            </g>
            <defs>
            <filter id="filter0_d" x="0.499999" y="0.470734" width="28.5" height="31.0585" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
            <feFlood floodOpacity="0" result="BackgroundImageFix"/>
            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
            <feOffset dy="4"/>
            <feGaussianBlur stdDeviation="2"/>
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0"/>
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow"/>
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape"/>
            </filter>
            </defs>
        </svg>
        
    );
}

export default IconPlay;