
import React from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import moment from 'moment';

// assets
import logo from '../../assets/logo.svg'
import IconCheck from '../../assets/icons/check.js'

class SideBar extends React.Component {
    constructor(props) {
        super(props);
        this.updateFilter = this.updateFilter.bind(this)
        this.handleClickOutside = this.handleClickOutside.bind(this)

        // Ref
        this.sidebarRef = React.createRef()
    }
    componentDidMount() {
        document.addEventListener('mousedown', this.handleClickOutside);
	}
	
	componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
    }
    
    handleClickOutside(event) {
		if (this.props.open && this.sidebarRef && this.sidebarRef.current && !this.sidebarRef.current.contains(event.target)) {
            this.props.close()
		}
    }


    updateFilter(name, data) {
        return () => {
            let filter = this.props.filter
            
            if (name === 'period') {
                filter[name] = data

            } else {
                if (filter[name].includes(data)) filter[name] = filter[name].filter(f => f !== data)
                else filter[name].push(data)
            }

            this.props.updateFilter(filter)
        }
    }

    buildUsers(filter_accounts) {
        if (this.props.users && this.props.users.twitter && this.props.users.twitter.length) {
            return this.props.users.twitter.map((user, index) => {
                if (!user.display_name) return null
                
                // Add selected classname
                let s = filter_accounts.includes(user.screen_name) ? 'selected' : ''

                // Data from
                let dataFrom = this.props.oldestVideoDate[`${user.screen_name}`] ? ` - from ${moment(this.props.oldestVideoDate[`${user.screen_name}`]).format('MMM Do YYYY')}` : ''

                return  (
                    <div className={`sidebar__section-item ${s} flex account`} key={`accounts-${index}`} onClick={this.updateFilter('accounts', user.screen_name)}>
                        <div className="sidebar__section-item-text">{user.display_name}</div> {s && <IconCheck/>}
                        <div className="sidebar__section-item-account">
                            <span className="sidebar__section-item-account-name">@{user.screen_name}</span>
                            <span className="sidebar__section-item-account-date">{dataFrom} </span>
                        </div> 
                    </div>
                )
            })
        } else {
            return (
                <div className="sidebar__section-item">
                    No accounts
                </div>
            )
        }
    }

    buildLanguages(filter_languages) {
        if (this.props.languages && this.props.languages.length) {
            return this.props.languages.map((l, index) => {
                
                // Add selected classname
                let s = filter_languages.includes(l) ? 'selected' : ''

                return  (
                    <div className={`sidebar__section-item ${s} flex`} key={`languages-${index}`} onClick={this.updateFilter('languages', l)}>
                        <div className="sidebar__section-item-text uppercase">{l}</div> {s && <IconCheck/>}
                    </div>
                )
            })
        } else {
            return (
                <div className="sidebar__section-item">
                    No accounts
                </div>
            )
        }
    }

    displaySidebar(elements) {
        if (window.innerWidth > 1024) {
            return (
                <PerfectScrollbar options={{wheelSpeed: 1, wheelPropagation: true, swipeEasing: false, minScrollbarLength: 5 }}>
                    {elements}
                </PerfectScrollbar>
            )
        } else {
            return (
                <div className="sidebar__scrollbar-mobile">
                    {elements}
                </div>
            )
        }
    }

    render() {
        const {period, accounts, formats, languages} = this.props.filter

        return (
            <div className={`sidebar ${this.props.open ? 'show' : ''}`} ref={this.sidebarRef}>
                {this.displaySidebar(
                    <>
                        <img className="sidebar__logo" src={logo} alt="logo" />

                        {/* Period */}
                        <div className="sidebar__section">
                            <div className="sidebar__section-title">
                                Period
                            </div>
                            <div className={`sidebar__section-item ${(period === 'all') && 'selected-period' }`} onClick={this.updateFilter('period', 'all')}>
                                All time
                            </div>
                            <div className={`sidebar__section-item ${(period === 'week') && 'selected-period' }`} onClick={this.updateFilter('period', 'week')}>
                                Last week
                            </div>
                            <div className={`sidebar__section-item ${(period === 'month') && 'selected-period' }`} onClick={this.updateFilter('period', 'month')}>
                                Last month
                            </div>
                            <div className={`sidebar__section-item ${(period === 'year') && 'selected-period' }`} onClick={this.updateFilter('period', 'year')}>
                                Last year
                            </div>
                        </div>

                        {/* Accounts */}
                        <div className="sidebar__section">
                            <div className="sidebar__section-title">
                                Accounts
                            </div>
                        {!this.props.users ? this.props.buildLoading('accounts') : this.buildUsers(accounts)}
                        </div>

                        {/* Accounts */}
                        <div className="sidebar__section">
                            <div className="sidebar__section-title">
                                Languages
                            </div>
                        {!this.props.languages ? this.props.buildLoading('languages') : this.buildLanguages(languages)}
                        </div>

                        {/* Formats */}
                        <div className="sidebar__section last">
                            <div className="sidebar__section-title">
                                Format
                            </div>
                            <div className={`sidebar__section-item flex ${formats.includes('1-1') && 'selected'}`} onClick={this.updateFilter('formats', '1-1')}>
                                1:1 <div className="sidebar__format-1-1"></div> {formats.includes('1-1') && <IconCheck/>}
                            </div>
                            <div className={`sidebar__section-item flex ${formats.includes('16-9') && 'selected'}`} onClick={this.updateFilter('formats', '16-9')}>
                                16:9 <div className="sidebar__format-16-9"></div> {formats.includes('16-9') && <IconCheck/>}
                            </div>
                            <div className={`sidebar__section-item  flex ${formats.includes('others') && 'selected'}`} onClick={this.updateFilter('formats', 'others')}>
                                <span>Others</span> {formats.includes('others') && <IconCheck/>}
                            </div>
                        </div>

                        {/* Contact */}
                        <div className={`sidebar__section-contact ${this.props.open ? 'show' : ''}`}>
                            <span className="sidebar__section-contact-item" data-popin="popin_detail" onClick={this.props.togglePopin}>How does It work ?</span>
                            <span className="sidebar__section-contact-item" data-popin="popin_detail" onClick={this.props.togglePopin}>Contact us</span>
                        </div>
                    </>
                )}
            </div>
        );
    }
}

export default SideBar;