import React from 'react';

// Init component.
function IconPause(props) {
	return (
		<svg className="icon__pause" width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
			<rect width="8" height="25" rx="2" fill="white"/>
			<rect x="17" width="8" height="25" rx="2" fill="white"/>
		</svg>
	);
}

export default IconPause;

