
import React from 'react';

// Icons
import IconPlay from '../../assets/icons/play'
import IconPause from '../../assets/icons/pause'

// Utils
import global  from '../utils/global'
class VideoPlayer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
			isPlay: false,
            currentTime: 0,
            realCurrentTime: 0,
            currentSound: 1
        };

        this.videoRef = React.createRef()

        this.handleClickOutside = this.handleClickOutside.bind(this)

        this.handlePlaying = this.handlePlaying.bind(this);
		this.handleTimeUpdate = this.handleTimeUpdate.bind(this);
		this.handlePause = this.handlePause.bind(this);
        this.handleChangeBar = this.handleChangeBar.bind(this);	
        this.playPauseVideo = this.playPauseVideo.bind(this);
        this.setVideoCurrentTime = this.setVideoCurrentTime.bind(this);
        
        this.handleChangeSound = this.handleChangeSound.bind(this);

        this.resizePopin = this.resizePopin.bind(this)
    }

    componentDidMount() {
        document.addEventListener('mousedown', this.handleClickOutside);
        window.addEventListener('resize', this.resizePopin );

	}
	
	componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
        window.removeEventListener('resize', this.resizePopin );
    }

    componentDidUpdate(prevProps) {
        if (!prevProps.open && this.props.open) this.resizePopin()
    }
    
    handleClickOutside(event) {
		if (this.props.open && this.videoRef && this.videoRef.current && !this.videoRef.current.contains(event.target)) {
            this.props.close()
            this.setState({isPlay: false, currentTime: 0, realCurrentTime: 0, currentSound: 1})
		}
    }
    
    handlePlaying(e) {
		this.setState({
			isPlay: true
		});
	}

	handleTimeUpdate(e) {
        // We store the current time of the video
        let currentTime = parseInt(((e.target.currentTime * 99)/this.props.video_info.duration.total_seconds),10)
        this.setState({
            currentTime: currentTime > 99 ? 99 : currentTime,
            realCurrentTime: e.target.currentTime
        });	
    }
    
	handlePause() {
		this.setState({
			isPlay: false
		})
    }
    
	handleChangeBar(e) {
		this.setState({
			currentTime: e.target.value > 99 ? 99 : e.target.value,
		},()=>{
			this.setVideoCurrentTime(this.state.currentTime);
		})
	}

	playPauseVideo() {
		this.setState(prevState => ({
			isPlay: !prevState.isPlay
		}),()=>{
			if(this.state.isPlay) {
				this.videoRef.current.querySelector('video').play();
			} else {
				this.videoRef.current.querySelector('video').pause();
			}
		});
	}

	setVideoCurrentTime(time) {
		let currentTime = parseInt(((time* this.props.video_info.duration.total_seconds)/99),10);
        this.videoRef.current.querySelector('video').currentTime  = currentTime;
    }

    handleChangeSound(e) {
		this.setState({
			currentSound: e.target.value > 99 ? 99 : e.target.value,
		}, ()=> {
            this.videoRef.current.querySelector('video').volume = this.state.currentSound
        })
    }
    
    resizePopin() {
        if (!this.videoRef || !this.videoRef.current || !this.props.open) return

        let width = parseInt((window.innerHeight * this.videoRef.current.offsetWidth) / this.videoRef.current.offsetHeight,10) - 30
        this.videoRef.current.style.width = `${width > 600 ? 600 : width}px`
    }

    render() {

        return (
            <div className={`video-player ${this.props.open ? 'is-open' : ''}`}>
                <div className="video-player__container" ref={this.videoRef}>
                    {this.props.open &&
                        <>
                            <video
                                onPlaying={this.handlePlaying} 
                                onTimeUpdate={this.handleTimeUpdate} 
                                onPause={this.handlePause}
                                src={this.props.video_info && this.props.video_info.video}
                                poster={this.props.video_info.poster}
                                type="video/mp4"
                                onLoadedData={this.resizePopin}
                                autoPlay
                            >
                                The video cannot be seen, please update your browser or try refreshing the actual page
                            </video>

                            {/* Player video */}
                            <div className="video-player__player">

                                {/* PROGRESSION BAR */}
                                <div className="video-player__player-bloc"> 
                                    <div className="video-player__player-bar background"></div>
                                    <input className="video-player__player-bar" type="range" min="0" max="99" value={this.state.currentTime}  onInput={this.handleChangeBar} readOnly/>
                                    <div className="video-player__player-progression" style={{transform:`translate(calc(${this.state.currentTime}% - 99%))`}}>
                                    </div>
                                </div>

                                {/* VIDEO CONTROLS */}
                                <div className="video-player__player-controls"> 
                                    <div className="video-player__player-controls-left"> 
                                        {/* BUTTON PLAY PAUSE */}
                                        <button className="video-player__player-btn" onClick={this.playPauseVideo}>
                                            {this.state.isPlay? 
                                                <IconPause/> 
                                                :
                                                <IconPlay/>  
                                            }
                                        </button>

                                        {/* VIDEO TIME : current time and total time of the video */}
                                        <div className="video-player__player-duration">
                                            <span className="video-player__player-duration-current">{global.getTimeFromMs(this.state.realCurrentTime * 1000).display}</span> <span className="video-player__player-duration-total">/ {this.props.video_info && this.props.video_info.duration.display}</span>
                                        </div>
                                    </div>

                                    {/* Sound control */}

                                     <div className="video-player__player-sound"> 
                                        <div className="video-player__player-sound-bar background"></div>
                                        <input className="video-player__player-sound-bar" type="range" min="0" max="1" step="0.01" value={this.state.currentSound}  onInput={this.handleChangeSound} readOnly/>
                                        <div className="video-player__player-sound-progression" style={{width:`${this.state.currentSound*100}px`}}></div>
                                    </div>
                                </div>
                            </div>
                        </>                   
                    }
                </div>
            </div>
        );
    }
}

export default VideoPlayer;