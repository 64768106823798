import React from 'react';

// Init component.
function IconSlider(props) {
	return (
        <svg className="icon__slider" width="24" height="20" viewBox="0 0 24 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M4 19V12" stroke="#232946" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M4 8V1" stroke="#232946" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M12 19V10" stroke="#232946" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M12 6V1" stroke="#232946" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M20 19V14" stroke="#232946" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M20 10V1" stroke="#232946" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M1 12H7" stroke="#232946" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M9 6H15" stroke="#232946" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M17 14H23" stroke="#232946" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    );
}

export default IconSlider;