import React from 'react';
import ReactDOM from 'react-dom';
import App from './app';
import history from './history';
import {Router, Route } from 'react-router-dom';

ReactDOM.render((
	<Router history={history}>
		<Route component={App}/>
	</Router>
), document.getElementById('root'))
