import React from 'react';

// Init component.
function IconRetweet(props) {
	return (
        <svg className="icon__retweet" width="15" height="10" viewBox="0 0 15 10" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M7.50001 0.499997L10 0.499997C11.1046 0.499997 12 1.39543 12 2.5L12 8.5M14.5 7L12 9.5L9.5 7M7.49999 9.5H4.99999C3.89542 9.5 2.99999 8.60457 2.99999 7.5V0.999997M0.5 3L3 0.499996L5.5 3" stroke="#707486" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    );
}

export default IconRetweet;