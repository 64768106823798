import React from 'react';

// Init component.
function IconHeart(props) {
	return (
        <svg className="icon__heart" width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M10.1456 2.24796C9.89027 1.99246 9.58705 1.78978 9.25332 1.6515C8.91959 1.51322 8.56189 1.44204 8.20065 1.44204C7.8394 1.44204 7.4817 1.51322 7.14797 1.6515C6.81424 1.78978 6.51102 1.99246 6.25565 2.24796L5.72565 2.77796L5.19565 2.24796C4.6798 1.73211 3.98016 1.44231 3.25065 1.44231C2.52113 1.44231 1.82149 1.73211 1.30565 2.24796C0.789799 2.7638 0.5 3.46344 0.5 4.19296C0.5 4.92247 0.789799 5.62211 1.30565 6.13796L1.83565 6.66796L5.72565 10.558L9.61565 6.66796L10.1456 6.13796C10.4011 5.88258 10.6038 5.57936 10.7421 5.24563C10.8804 4.9119 10.9516 4.5542 10.9516 4.19296C10.9516 3.83171 10.8804 3.47401 10.7421 3.14028C10.6038 2.80655 10.4011 2.50333 10.1456 2.24796V2.24796Z" stroke="#707486" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    );
}

export default IconHeart;