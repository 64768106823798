
const api = {

    getUser(token){
        return fetch(`${process.env.REACT_APP_API_URL}/user?token=${token}`)
        .then(res => res.json())
        .then(res => {return res})
        .catch(res => {return res})
    },
    async getUserTweet(token, i){
       let userTweets = await fetch(`${process.env.REACT_APP_API_URL}/user/tweet?token=${token}${i ? `&offset_by_account=${i*2}0` : ''}`)
       userTweets = await userTweets.json()

       return userTweets
    }
}

export default api;
