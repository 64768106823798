
import React from 'react';

class Popin extends React.Component {
    constructor(props) {
        super(props);

        this.popinRef = React.createRef()
        this.handleClickOutside = this.handleClickOutside.bind(this)
    }

    componentDidMount() {
        document.addEventListener('mousedown', this.handleClickOutside);
	}
	
	componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
    }

    handleClickOutside(event) {
        const open = this.props.popin.detail
		if (open && this.popinRef && this.popinRef.current && !this.popinRef.current.contains(event.target)) {
            this.props.togglePopin(null)
		}
    }
    
    render() {
        const open = this.props.popin.detail
        return (
            <div className={`popin ${open ? 'is-open' : ''}`}>
                {this.props.popin.detail &&
                    <div className="popin__container" ref={this.popinRef}>
                        <div className="popin__title">
                            What is the Social Time Machine?
                        </div>

                        <div className="popin__text">
                            Social time Machine enables brands, media outlets or people to capture a new point of view <br/>
                            regarding the content they share or discover on Twitter. Its main purpose is to enable brands <br/>
                            to build an instant video library that they can use internally. Effortlessly.<br/><br/>

                            It can compile and showcase their content in an organized fashion and can also monitor the <br/>
                            brands and topics that matter to its users.<br/><br/>

                            A mix of videos organized with filters and smart indicators that make you quickly understand <br/>
                            what is meaningful and find what you are looking for (and eliminate what you don't want to see).
                        </div>
                        <div className="popin__title">
                            How does it work?
                        </div>

                        <div className="popin__text">
                            We use the official Twitter API to fetch the last 4200 tweets of specific Twitter accounts.<br/>
                            We keep only the tweets with native videos and use the data of the tweet to create filters, <br/>
                            statistics and nicely organize your content.<br/>
                            We do not take RT or replies into consideration but they are counted within the Twitter API <br/>
                            limitation of 4200 tweets, this is why some accounts might see very few videos. If interested <br/>
                            you can pay to retrieve your Twitter history entirely.
                        </div>

                        <div className="popin__title">
                            Would you like a customized version for yourself?
                        </div>

                        <div className="popin__text">
                            This tool was built as part of a <a href="http://saastory.com/" rel="noopener noreferrer" target='_blank'>SaaStory</a> experiment. If you'd like a version of your own with <br/>
                            more features : <br/>
                            <span className="popin__item">- A lot more tweets history</span> <br/>
                            <span className="popin__item">- Specific twitter accounts to monitor,</span> <br/>
                            <span className="popin__item">- Weekly digests by email,</span> <br/>
                            <span className="popin__item">- More platforms,</span> <br/>
                            <span className="popin__item">- More statistics,</span> <br/>
                            <span className="popin__item">- Automatically generated video summaries,</span> <br/>
                            <span className="popin__item">- Alerts</span> <br/>
                            <span className="popin__item more-space">etc…</span>
                        </div>

                        <div className="popin__title">
                            Contact the team
                        </div>

                        <div className="popin__text">
                            Feel free to <a href="mailto:contact@saastory.com">contact us</a>. We like challenges and solving problems. <br/><br/>
                            PS: we have plenty more dashboards like this one. Just send us the words "show me more"<br/>
                            by email.
                        </div>
                    </div>
                }
            </div>
        );
    }
}

export default Popin;