
import React from 'react';
import moment from 'moment';
import PerfectScrollbar from 'react-perfect-scrollbar';

// import components
import SideBar from '../sideBar/sideBar'
import VideoPlayer from '../videoPlayer/videoPlayer'
import Popin from '../popin/popin'
import Tooltip from '../tooltip/tooltip'
import history from '../../history'

// import utils
import api  from '../utils/api'
import global  from '../utils/global'
import stat  from '../utils/stat'

// import assets
import IconInfo from '../../assets/icons/info'
import IconArrow from '../../assets/icons/arrow'
import IconTwitter from '../../assets/icons/twitter'
import IconRetweet from '../../assets/icons/retweet'
import IconHeart from '../../assets/icons/heart'
import IconPlay from '../../assets/icons/play'
import IconDownload from '../../assets/icons/download'
import IconSlider from '../../assets/icons/slider'
import logo from '../../assets/logo.svg'

class Dashboard extends React.Component {
    constructor(props) {
        super(props);

        this.LIMIT_VIDEO = 30

        this.state = {
            // Containing information of each accounts
            users: null,

            // All tweets per account (video, photo, link, rt)
            userTweets: null,

            // All tweets video per account
            userTweetsVideo: null,

            // Containing the total tweets filtered (Used to render videos in the dasboard)
            tweetsVideo_filtered: [],

            // Containing the total tweets filtered per account
            userTweetsVideo_filtered: [],

            limit_video: this.LIMIT_VIDEO,

            // When loading more tweet (scroll at the bottom)
            isLoadingTweets: false,

            // When updating filter
            loading: true,
            languages: null,

            isDropdown : false,
            topHashtags: null,
            isVideoPlayer: false,
            isSidebar: false,
            popin_detail: false,
            video_info: null,
            
            filter: {
                period: 'all',
                accounts: [],
                languages: [],
                hashtags: [],
                formats: [],
                date_from: null,
                date_to: null
            },
            sort: 'Date',

            // Stat
            total_videos: null, 
            total_languages: {number: 0, list: null}, 
            total_time: {number: 0, text: 'seconds'}, 
            avg_engagement: 0, 
            avg_video_ratios: 0,
            avg_text_ratios: 0,
            avg_photo_ratios: 0,
            avg_gif_ratios: 0,
            avg_link_ratios: 0,
            avg_frequency_publication: 0,

            oldestVideoDate: {},
            screenNameToDisplayName: {}
        };

        // Save the last Y position of dashboard content
        this.posY = 0

        // Ref
        this.stickyRef = React.createRef()

        this.loadingData = this.loadingData.bind(this)
        this.openSort = this.openSort.bind(this)
        this.closeSort = this.closeSort.bind(this)
        this.chooseSort = this.chooseSort.bind(this)

        this.updateFilterhashtags = this.updateFilterhashtags.bind(this)

        this.openVideoPlayer = this.openVideoPlayer.bind(this)
        this.closeVideoPlayer = this.closeVideoPlayer.bind(this)

        this.showSidebar = this.showSidebar.bind(this)
        this.togglePopin = this.togglePopin.bind(this)

        this.updateFilter = this.updateFilter.bind(this)
        this.filterContent = this.filterContent.bind(this)

        this.resizeVideosContainer = this.resizeVideosContainer.bind(this)
        this.setStatSticky = this.setStatSticky.bind(this)
        this.loadMoreTweets = this.loadMoreTweets.bind(this)

        this.handleScrollMobile = this.handleScrollMobile.bind(this)
    }

    async componentDidMount() {
        window.addEventListener('resize', this.resizeVideosContainer );
        window.addEventListener('scroll', this.handleScrollMobile );

        this.loadingData()
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.resizeVideosContainer );
        window.addEventListener('scroll', this.handleScrollMobile );
    }

    async loadingData() {
        let params = new URLSearchParams(history.location.search);
        let token = params.get('token')
        let max_call_api =  params.get('light')

        // Max call api
        try {
            if (max_call_api) {
                max_call_api = parseInt(max_call_api) ? parseInt(max_call_api)  : null
                max_call_api = !isNaN(max_call_api) ? max_call_api : null
                max_call_api = max_call_api && (max_call_api > 50) ? 49 : ((max_call_api - 1) < 0 ? 0 : (max_call_api - 1))
            }
        } catch(e) {
            console.error(e)
            max_call_api = null
        }

        max_call_api = max_call_api !== null ? max_call_api : 49

        // Handling custom period
        let date_from = params.get('date_from')
        let date_to = params.get('date_to')
        
        if (date_from || date_to) {
            let filter = this.state.filter
            filter.date_from = date_from
            filter.date_to = date_to
            filter.period = 'custom'
            this.setState({filter})
        }

        let i = 0, isEmpty = true

        if (token ) {
            let users = await api.getUser(token)

            let screenNameToDisplayName =  {}

            if (users && users.twitter && users.twitter.length) {
                for (let user of users.twitter) {
                    screenNameToDisplayName[`${user.screen_name}`] = user.display_name
                }
            }

            this.setState({screenNameToDisplayName})

            // Set loading
            document.querySelector('.loader').classList.add('is-loading')

            while (true) {
                isEmpty = true
                let userTweets = this.state.userTweets
                let userTweetsNew = await api.getUserTweet(token, i)

                // If an error occur
                if (userTweetsNew && userTweetsNew.error) {
                    break;
                }
               
                if (!userTweets) {
                    userTweets = userTweetsNew
                    isEmpty = false
                } else {
                    for (let userTweet in userTweetsNew) {
                        if (userTweetsNew[userTweet] && userTweetsNew[userTweet].length) {
                            userTweets[`${userTweet}`] = userTweets[`${userTweet}`].concat(userTweetsNew[`${userTweet}`])
                            isEmpty = false
                        }
                    }
                }

                // Filter retweet and quote
                userTweets = global.filterRetweetAndQuote(Object.assign({}, userTweets))

                // Get only tweets video per account
                let userTweetsVideo = global.getUserTweetsVideo(Object.assign({}, userTweets))

                await this.setState({users, userTweets, userTweetsVideo})

                this.updateFilter(this.state.filter, {disableLoading: true})

                try {
                    if (!i) this.resizeVideosContainer()
                } catch (e) {
                    console.error(e)
                }
                if (isEmpty || (i === max_call_api)) break;
                i++
            }
    
            // Api loading is done
            document.querySelector('.loader').classList.remove('is-loading')
                
        } 
    }

    openSort() {
        this.setState({isDropdown: true})
    }

    closeSort() {
        if (!this.state.isDropdown) return 

        this.setState({isDropdown: false})
    }

    chooseSort(e) {
        this.closeSort()
        this.setState({sort: e.currentTarget.getAttribute('data-sort')}, () => {
            this.updateFilter(this.state.filter, {origin: 'sort'})
        })
    }

    updateFilterhashtags(e) {
        let filter = this.state.filter,
        hashtag = e.currentTarget.getAttribute('data-hashtag')
        
        if (filter.hashtags.includes(hashtag)) filter.hashtags = filter.hashtags.filter(h => h !== hashtag)
        else filter.hashtags.push(hashtag)

        this.setState({filter}, () => {
            this.updateFilter(filter, {origin: 'hashtags'})
        })
    }

    openVideoPlayer(params) {
        return (e) => {
            this.setState({
                isVideoPlayer: true,
                video_info: {
                    video: params.video,
                    poster: params.poster,
                    duration: params.duration
                },
            })
        }
    }

    closeVideoPlayer() {
        if (!this.state.isVideoPlayer) return 

        this.setState({isVideoPlayer: false})
    }

    showSidebar() {
        this.setState({isSidebar: !this.state.isSidebar})
    }

    togglePopin(e) {
        if (e) {
            let popin = e.currentTarget.getAttribute('data-popin')
            this.setState({[`${popin}`]: !this.state[`${popin}`]})
        } else {
            this.setState({popin_detail: false})
        }
      
    }

    async updateFilter(filter, options = null) {
        // Reset hashtags filter (only If the update do not come from hashtags or sort update)
        if (!(options && options.origin)) filter.hashtags = []

        filter.languages = options && options.disableLoading ? [] : filter.languages

        await this.setState({filter, limit_video: this.LIMIT_VIDEO, loading: options && options.disableLoading ? false : true})

        const filteringTweets = new Promise((resolve, reject) => {
            setTimeout(() => {
                let userTweetsVideo_filtered = Object.assign({}, this.state.userTweetsVideo),
                userTweets = Object.assign({}, this.state.userTweets), 
                users = this.state.users,
                filter_accounts = filter.accounts,
                tweetsVideo_filtered = [],
                oldestVideoDate = {}
        
                // filter accounts
                if (filter_accounts.length) {
                    users = users.twitter.filter(u => !filter_accounts.includes((u.screen_name))).map(u => u.screen_name) 

                    for (let u of users) {delete userTweetsVideo_filtered[u] }
                }
        
                if (userTweetsVideo_filtered) {
        
                    // Building array containing all the tweets
                    for (let userTweet in userTweetsVideo_filtered) {
        
                        // Filter the tweets video per accounts
                        userTweetsVideo_filtered[userTweet] = userTweetsVideo_filtered[userTweet].filter(userTweet => this.filterContent(userTweet))

                        // Filter the total tweets per account If the filter period is different from "all"
                        if (this.state.filter.period !== 'all') {
                            userTweets[`${userTweet}`] = userTweets[`${userTweet}`].filter(userTweet => this.filterContent(userTweet, {onlyPeriod: true}))
                        }

                        // Filter the total tweets per account If the filter languages is filled
                        if (this.state.filter.languages.length) {
                            userTweets[`${userTweet}`] = userTweets[`${userTweet}`].filter(userTweet => this.filterContent(userTweet, {onlyLang: true}))
                        }

                        // Filter the total tweets per account If the filter languages is filled
                        if (this.state.filter.hashtags.length) {
                            userTweets[`${userTweet}`] = userTweets[`${userTweet}`].filter(userTweet => this.filterContent(userTweet, {onlyHash: true}))
                        }
                        
                        // Building array containing all the tweets videos to render
                        tweetsVideo_filtered = tweetsVideo_filtered.concat(userTweetsVideo_filtered[userTweet].map (u => {
                            u.screen_name = `${userTweet}`
                            return u
                        }))

                        // Get the oldest video date
                        oldestVideoDate[`${userTweet}`] = global.getOldestVideoDate(userTweetsVideo_filtered[`${userTweet}`])
                    }
        
                    // Sort all the tweets videos to render
                    tweetsVideo_filtered = global.sortContent({
                        tweets: tweetsVideo_filtered.filter(userTweet => this.filterContent(userTweet)),
                        sort: this.state.sort
                    })
                }

                // Get top hashtags
                const topHashtags = options && (options.origin === 'hashtags') ? this.state.topHashtags : stat.getTopHashtags(userTweetsVideo_filtered)

                // Calculate stat
                const {
                    total_videos, 
                    total_languages, 
                    avg_video_duration, 
                    avg_engagement, 
                    avg_video_ratios, 
                    avg_text_ratios,
                    avg_photo_ratios,
                    avg_gif_ratios,
                    avg_link_ratios,
                    avg_frequency_publication
                } = stat.getStats({tweetsVideo: tweetsVideo_filtered, userTweetsVideo: userTweetsVideo_filtered, userTweets: userTweets, filter: filter})

                resolve({
                    tweetsVideo_filtered, 
                    userTweetsVideo_filtered, 
                    total_videos, 
                    total_languages, 
                    avg_video_duration, 
                    avg_engagement, 
                    avg_video_ratios,
                    avg_text_ratios,
                    avg_photo_ratios,
                    avg_gif_ratios,
                    avg_link_ratios,
                    avg_frequency_publication, 
                    topHashtags, 
                    oldestVideoDate, 
                    loading: false
                })
            }, options && options.disableLoading ? 0 : 100);
        });

        let res = await filteringTweets

        // Add languages list
        if (options && options.disableLoading) res.languages = res.total_languages.list

        this.setState(res)
    }   

    filterContent(userTweet, options) {
        const {period, hashtags, formats, languages} = this.state.filter
        let isValid = true;

        // Period
        if (period !== 'all' && !(options && (options.onlyLang || options.onlyHash))) {

            if (period !== 'custom') {
                isValid = moment(userTweet.created_at).isAfter(moment().subtract(1, period))

            } else if (period === 'custom') {
                let date_from = this.state.filter.date_from
                let date_to = this.state.filter.date_to

                try {
                    if (date_from && date_to) {
                        isValid = moment(userTweet.created_at).isBetween(moment(date_from, 'DD/MM/YYYY'), moment(date_to, 'DD/MM/YYYY')) || moment(userTweet.created_at).isSame(moment(date_from, 'DD/MM/YYYY'), 'day') || moment(userTweet.created_at).isSame(moment(date_to, 'DD/MM/YYYY'), 'day')
                    
                    } else if (date_from && !date_to) {
                        isValid = moment(userTweet.created_at).isAfter(moment(date_from, 'DD/MM/YYYY')) || moment(userTweet.created_at).isSame(moment(date_from, 'DD/MM/YYYY'), 'day')
                   
                    } else if (!date_from && date_to) {
                        isValid = moment(userTweet.created_at).isBefore(moment(date_to, 'DD/MM/YYYY')) || moment(userTweet.created_at).isSame(moment(date_to, 'DD/MM/YYYY'), 'day')
                    }
                } catch(e) {
                    isValid = true
                }
            }

            if (options && options.onlyPeriod) return isValid
        }

        // Languages
        if (isValid && languages.length && !(options && options.onlyHash)) {
            isValid = languages.includes(userTweet.lang)

            if (options && options.onlyLang) return isValid
        }

        // Hashtags
        if (isValid && hashtags.length && global.isFilledArray(userTweet.hashtags)) {
            let userTweetHashtags = JSON.parse(userTweet.hashtags).map(h => h.toLowerCase())
            isValid = userTweetHashtags.some(item => hashtags.includes(item)) 

            if (options && options.onlyHash) return isValid

        } else if (isValid && hashtags.length && !global.isFilledArray(userTweet.hashtags)) {
            isValid = false

            if (options && options.onlyHash) return isValid
        }


        // Formats
        if (isValid && formats.length) {
            isValid = formats.includes(userTweet.media_ratio) || (formats.includes('others') && !['16-9', '1-1'].includes(userTweet.media_ratio))
        }

        return isValid
    }

    resizeVideosContainer() {
        let videos = document.querySelector('.dashboard__container .scrollbar-container')
        if (!videos) return
        videos.style.height =  `${videos.offsetHeight + (window.innerHeight - (videos.offsetTop + videos.offsetHeight))}px`
    }

    setStatSticky(direction) {
        return (e) => {
            try {
                if (direction === 'down' && this.stickyRef.current.offsetTop > 90) {
                    this.stickyRef.current.style.transform = `translateY(-${this.stickyRef.current.querySelector('.dashboard__stats').offsetHeight}px)`
                } else {
                    this.stickyRef.current.style.transform = `translateY(0px)`
                }
              
            } catch (err) {
                console.error(err)
            }
        }
    }

    async loadMoreTweets(e) {
        let limit_video = this.state.limit_video
        
        if (e.scrollTop > 0 && !this.state.isLoadingTweets && (limit_video < this.state.tweetsVideo_filtered.length) && !this.state.loading) {
            await this.setState({isLoadingTweets: true})
            this.setState({limit_video: this.state.limit_video + 8, isLoadingTweets: false })
        }
    }

    async handleScrollMobile(e) {

        let limit_video = this.state.limit_video
        if ((window.innerWidth > 1024) || !(!this.state.isLoadingTweets && (limit_video < this.state.tweetsVideo_filtered.length) && !this.state.loading)) return

        let dash_container = document.querySelector('.dashboard__container')

        // Set animation on stat
        const direction = this.posY > dash_container.getBoundingClientRect().top ? 'down' : 'up'
        this.posY = dash_container.getBoundingClientRect().top
    
        
        if (direction === 'down' && dash_container.querySelector('.dashboard__content').getBoundingClientRect().top < 90) {
            this.stickyRef.current.style.transform = `translateY(-${this.stickyRef.current.querySelector('.dashboard__stats').offsetHeight + 20}px)`
        } else {
            this.stickyRef.current.style.transform = `translateY(0px)`
        }

        // If we reach the bottm 
        if (Math.round(window.innerHeight + window.scrollY ) >= dash_container.offsetHeight) {
            await this.setState({isLoadingTweets: true})
            this.setState({limit_video: this.state.limit_video + 8, isLoadingTweets: false})
        }
    }

    buildHome() {
        return (
            <div className="dashboard__home">
                <img className="dashboard__home-logo" src={logo} alt="logo"/>
                <div className="dashboard__home-title">
                    Social Time Machine coming soon 
                </div>
                <div className="dashboard__home-text">
                    In the meantime, please visit the <span onClick={()=> {history.push('/?token=yUBdLBYeYlljVrYF6M3tpWhX8FZFFQgG'); this.loadingData()}}>CAC40 dashboard</span> 
                </div>
                <div className="dashboard__home-text legend">
                    Social Time Machine is a product of <a href="http://saastory.com/" rel="noopener noreferrer" target='_blank'>saastory.com</a>, <a href="mailto:contact@saastory.com">contact us</a>
                </div>
            </div>
        )
    }
    buildTopHashtags() {
        if (this.state.topHashtags && this.state.topHashtags.length) {
            return this.state.topHashtags.map((h, index) => {

                // Add selected classname
                let s = this.state.filter.hashtags.includes(h[0]) ? 'selected' : ''
                
                return  (
                    <div className={`hashtag ${s}`} key={index} data-hashtag={h[0]} onClick={this.updateFilterhashtags}>
                        <span className="hashtag__text">#{h[0]}</span> 
                        <span className="hashtag__data">{h[1]}</span>
                    </div>
                )
            })
        } else {
            return ''
        }
    }

    buildVideos(options) {
        let tweetsVideo_filtered = this.state.tweetsVideo_filtered, 
        videos = [],
        userTweet = null,
        duration = null

        for (let i = 0 , j = this.state.limit_video; i < j; i++) {

            if (tweetsVideo_filtered[i]) userTweet = tweetsVideo_filtered[i]
            else break;

            duration = global.getTimeFromMs(userTweet.media_duration_ms)
            videos.push(
                <div className="dashboard__video" key={userTweet.tweet_id}>
                    {/* BLOC VIDEO */}
                    <div className="dashboard__video-bloc" onClick={!options.isTablet ? this.openVideoPlayer({duration, video: userTweet.media_url_hd,poster: userTweet.media_thumbnail}) : null}>

                        {/* BUTTON DOWNLOAD */}
                        <a onClick={(e)=> {e.stopPropagation()}} href={userTweet.media_url_hd} className="dashboard__video-download" rel="noopener noreferrer" target='_blank'>
                            <IconDownload/> 
                        </a>
                        
                        {/* BUTTON PLAY */}
                        <button className="dashboard__video-play">
                            <IconPlay/>
                        </button>

                        {/* Twitter screen name */}
                        <div className="dashboard__video-account">
                            <span className="dashboard__video-account-name">
                                @{userTweet.screen_name} 
                            </span>
                        </div>

                        {/* VIDEO DURATION */}
                        <span className="dashboard__video-duration">
                            {duration.display} 
                        </span>

                        {/* VIDEO */}
                        <video 
                            muted 
                            poster={userTweet.media_thumbnail}
                            controls={options.isTablet}
                            src={options.isTablet ? userTweet.media_url_hd : null}
                        >
                            The video cannot be seen, please update your browser or try refreshing the actual page
                        </video>
                    </div>

                    {/* TAGS */}
                    {global.isFilledArray(userTweet.hashtags) &&
                        <div className="dashboard__video-tags">
                            {JSON.parse(userTweet.hashtags).map((tag, index) => {
                                return (
                                    <div className="hashtag" key={index}>
                                        #{tag}
                                    </div>
                                )
                            })}
                        </div>
                    }
                    
                    {/* INFO */}
                    <div className="dashboard__video-info"> 
                        <a className="dashboard__video-link" href={`https://twitter.com/${userTweet.screen_name}/status/${userTweet.tweet_id}`} rel="noopener noreferrer" target='_blank'>
                            <IconTwitter/> 
                            <span className="dashboard__video-info-date">
                                {moment(userTweet.created_at).format('MMM Do YYYY, hh:mma')}
                            </span>
                        </a>

                        <div className="dashboard__video-info-rt">
                            <div className="dashboard__video-info-rt-data">{userTweet.retweets}</div>
                            <IconRetweet/> 
                        </div>

                        <div className="dashboard__video-info-fav">
                            <div className="dashboard__video-info-fav-data">{userTweet.favorites}</div>
                                <IconHeart/>
                        </div>
                    </div>
                </div>
            )
        }

        if (!videos.length) videos.push(<div className="dashboard__video-empty" key="loading">It seems there is no video to display </div>)

        return videos
    }

    buildStats(options) {

        let  total_videos_per_account = []
        if (this.state.userTweetsVideo_filtered) {
            for (let userTweetsVideo in this.state.userTweetsVideo_filtered) {
                let data = {
                        display_name: this.state.screenNameToDisplayName[`${userTweetsVideo}`],
                        total_videos: this.state.userTweetsVideo_filtered[`${userTweetsVideo}`].length, // Total video
                        total_likes: this.state.userTweetsVideo_filtered[`${userTweetsVideo}`].map(v => v.favorites), // avg likes
                        total_retweets: this.state.userTweetsVideo_filtered[`${userTweetsVideo}`].map(v => v.retweets) // avg retweets
                    }
                    
                data.total_likes = data.total_likes.length ? Math.round(data.total_likes.reduce((a, b) => a + b, 0)  / data.total_likes.length) : 0
                data.total_retweets = data.total_retweets.length? Math.round(data.total_retweets.reduce((a, b) => a + b, 0)  / data.total_retweets.length) : 0
                total_videos_per_account.push(data)
            }
        }

        return (
            <>
                <Tooltip 
                    text={
                        <>
                           Total number of videos among the selected accounts and filters.
                            <br/><br/>
                          {
                              total_videos_per_account.sort((a, b) => {return b.total_videos - a.total_videos}).map((v, index)=> {
                                  return (
                                    <div  className="tooltip__rank" key={index}>
                                        <div  className="tooltip__rank-number">#{index+1} </div>
                                        <div  className="tooltip__rank-item">{v.display_name} ({v.total_videos} video{v.total_videos > 1 ? 's' : ''})</div>
                                      
                                    </div>
                                  )
                              })
                          }  
                        </>
                    }
                    perfectscroll={!(options && options.isTablet)}
                    className="total-videos"
                >
                    <div className="dashboard__stats-item">
                        <div className="dashboard__stats-item-data">
                        {this.state.total_videos}
                        </div>
                        <div className="dashboard__stats-item-legend">
                            TOTAL VIDEOS <IconInfo/>
                        </div>
                    </div>
                </Tooltip>

                <Tooltip text="Average video publication frequency per account.">
                    <div className="dashboard__stats-item">
                        <div className="dashboard__stats-item-data">
                            {this.state.avg_frequency_publication}
                        </div>
                        <div className="dashboard__stats-item-legend">
                        VIDEOS / WEEK <IconInfo/>
                        </div>
                    </div>
                </Tooltip>

                <Tooltip text={
                    <>
                        This ratio is based on selected filters but only takes into consideration:
                        <br/><br/>
                        
                        - {this.state.avg_video_ratios}% of videos <br/>
                        - {this.state.avg_photo_ratios}% of images <br/>
                        - {this.state.avg_link_ratios}% of links <br/>
                        - {this.state.avg_gif_ratios}% of GIFs <br/>
                        - {this.state.avg_text_ratios}% of text

                        <br/><br/>
                        <span className="tooltip__legend">
                            RT or Replies aren't taken into account.
                        </span>
                    </>
                }>
                    <div className="dashboard__stats-item">
                        <div className="dashboard__stats-item-data">
                        {this.state.avg_video_ratios}%
                        </div>
                        <div className="dashboard__stats-item-legend">
                        VIDEOS RATIO <IconInfo/>
                        </div>
                    </div>
                </Tooltip>

                <Tooltip text="Average video duration per account.">
                    <div className="dashboard__stats-item">
                        <div className="dashboard__stats-item-data">
                            {this.state.avg_video_duration.hours ? <>{this.state.avg_video_duration.hours}<span className="dashboard__stats-item-data time">hr </span></> : ''}
                            {this.state.avg_video_duration.minutes ? <>{this.state.avg_video_duration.minutes}<span className="dashboard__stats-item-data time">mn </span></> : ''}
                            {this.state.avg_video_duration.seconds ? <>{this.state.avg_video_duration.seconds}<span className="dashboard__stats-item-data time">s</span></> : ''}
                            {!this.state.avg_video_duration.seconds && !this.state.avg_video_duration.minutes && !this.state.avg_video_duration.hours  ? <>0<span className="dashboard__stats-item-data time">s</span></> : ''}
                        </div>
                        <div className="dashboard__stats-item-legend">
                            AV. DURATION <IconInfo/>
                        </div>
                    </div>
                </Tooltip>

                <Tooltip 
                    text={
                        <>
                           Average likes + RT per Tweet among the selected accounts and filters.
                            <br/><br/>
                          {
                              total_videos_per_account.sort((a, b) => {return b.total_retweets - a.total_retweets}).map((v, index)=> {
                                  return (
                                    <div  className="tooltip__rank"  key={index}>
                                        <div className="tooltip__rank-number">#{index+1}</div>
                                        <div className="tooltip__rank-item">{v.display_name} ({v.total_retweets} rt{v.total_retweets > 1 ? 's' : ''}, {v.total_likes} like{v.total_likes > 1 ? 's' : ''})</div>
                                      
                                    </div>
                                  )
                              })
                          }  
                        </>
                    }
                    perfectscroll={!(options && options.isTablet)}
                    className="total-engagement"
                >
                    <div className="dashboard__stats-item">
                        <div className="dashboard__stats-item-data">
                        {this.state.avg_engagement}
                        </div>
                        <div className="dashboard__stats-item-legend">
                            AV. ENGAGEMENT <IconInfo/>
                        </div>
                    </div>
                </Tooltip>

                <Tooltip text="Total number of languages used among the selected accounts and filters.">
                    <div className="dashboard__stats-item last">
                        <div className="dashboard__stats-item-data">
                        {this.state.total_languages.number}
                        </div>
                        <div className="dashboard__stats-item-legend">
                            TOTAL LANGUAGES <IconInfo/>
                        </div>
                    </div>
                </Tooltip>
            </>
        )
    }

    buildStickyBar(options = null) {
        return (
            /*********************/
            /* STICKY BAR */
            /*********************/
            <div className="dashboard__sticky-bar" ref={this.stickyRef}>
                {/*********************/}
                    {/* STATS */}
                {/*********************/}
                <div className="dashboard__stats">
                    {/*<div className="dashboard__stats-legend">
                        Data from friday 5 may 2020 (1 month)
                    </div>*/}
                    <div className="dashboard__stats-container">
                        { this.state.total_videos === null ?
                            this.buildLoading('stat')
                        :
                            this.buildStats(options)
                        }
                    </div>
                </div>

                {/*********************/}
                    {/* sticky content */}
                {/*********************/}
                <div className="dashboard__sticky-content">
                    {/*********************/}
                        {/* HASHTAGS */}
                    {/*********************/}
                    {!this.state.topHashtags || (this.state.topHashtags && this.state.topHashtags.length) ?
                        <div className="dashboard__hashtags">
                            {this.state.topHashtags === null ? this.buildLoading('hashtags') : this.buildTopHashtags()}
                        </div>
                    : ''}

                    {/*********************/}
                        {/* SORT */}
                    {/*********************/}
                    {this.state.topHashtags !== null &&
                        <div className="dashboard__sort">
                            {/* Sort label */}
                            <span className="dashboard__sort-label">Sort by</span>

                            {/* SORT OPTION */}
                            <div className="dashboard__sort-bloc" onMouseLeave={this.closeSort}>

                                {/* Sort input */}
                                <input className={`dashboard__sort-input ${this.state.isDropdown ? 'is-open' : ''}`}  type="text" placeholder="Select a type" onMouseDown={this.openSort} value={this.state.sort} readOnly/>
                                <IconArrow/>
                                
                                {/* Sort dropdown */}
                                <div className={`dashboard__sort-dropdown ${this.state.isDropdown ? 'is-open' : ''}`}>
                                    <ul className="dashboard__sort-list">
                                        {this.state.sort !== 'Date' &&
                                            <li className="dashboard__sort-item" data-sort="Date" onClick={this.chooseSort}>
                                                Date
                                            </li>
                                        }
                                        {this.state.sort !== 'Retweets' &&
                                            <li className="dashboard__sort-item" data-sort="Retweets" onClick={this.chooseSort}>
                                                Retweets
                                            </li>
                                        }
                                        {this.state.sort !== 'Likes' &&
                                            <li className="dashboard__sort-item last" data-sort="Likes" onClick={this.chooseSort}>
                                                Likes
                                            </li>
                                        }
                                    </ul>
                                </div>
                            </div>
                        </div>
                    }
                </div>
            </div>
        )
    }

    buildLoading(name) {
        let loadings = [],
        count = (name === 'video') ? 12 : 
                (name === 'hashtags') ? 11 : 
                (name === 'stat') ? 5 :
                (name === 'accounts') ? 7 :
                (name === 'languages') ? 4 : 0

        for (let i = 0; i < count; i++) {loadings.push(<div key={`${name}-${i}`}className={`dashboard__loading ${name}`}></div>)}

        return loadings
    }

    render() {
        let params = new URLSearchParams(history.location.search);
        let token = params.get('token')

        // If there is no token build home page
        if (!token) return  (<div className="dashboard home">{this.buildHome()}</div>)

        const isTablet = !!(window.innerWidth <= 1024)
        const videos = this.buildVideos({isTablet})

        return (
            <div className="dashboard">
                {/*********************/}
                    {/* POPIN */}
                {/********************/}
                <Popin togglePopin={this.togglePopin} popin={{detail: this.state.popin_detail}}/>

                {/*********************/}
                    {/* VIDEO PLAYER */}
                {/********************/}
                <VideoPlayer video_info={this.state.video_info} close={this.closeVideoPlayer} open={this.state.isVideoPlayer}/>

                {/*********************/}
                    {/* SIDEBAR */}
                {/********************/}
                <SideBar users={this.state.users} languages={this.state.languages} filter={this.state.filter} open={this.state.isSidebar} oldestVideoDate={this.state.oldestVideoDate} close={this.showSidebar} updateFilter={this.updateFilter} buildLoading={this.buildLoading} togglePopin={this.togglePopin}/>
                
                <div className={`dashboard__container ${this.state.isSidebar ? 'disable' : ''}`} >
                    { isTablet && 
                        <>
                            {/* Sticky bar */}
                            {this.buildStickyBar({isTablet})}

                            {/*********************/}
                                {/* MAIN CONTENT */}
                                
                            {/*********************/}
                            {!this.state.loading && <p className="dashboard__message-info">This dashboard is a demo version that doesn't show live data anymore. If interested please <a href="mailto:contact@saastory.com">contact us</a>.</p> }

                            <div className="dashboard__content">

                                {/* VIDEOS */}
                                <div className="dashboard__videos" >
                                    {this.state.loading ? this.buildLoading('video') : videos}
                                </div>
                            
                            </div>
                        </>
                    }

                    { !isTablet && 
                        <PerfectScrollbar onYReachEnd={this.loadMoreTweets} onScrollUp={this.setStatSticky('up')} onYReachStart={this.setStatSticky('up')} onScrollDown={this.setStatSticky('down')} options={{wheelSpeed: 1, wheelPropagation: true, swipeEasing: false, minScrollbarLength: 20 , suppressScrollX: true}}>
                            {/* Sticky bar */}
                            {this.buildStickyBar()}

                            {/*********************/}
                                {/* MAIN CONTENT */}
                            {/*********************/}

                            {!this.state.loading && <p className="dashboard__message-info">This dashboard is a demo version that doesn't show live data anymore. If interested please <a href="mailto:contact@saastory.com">contact us</a>.</p> }
                            
                            <div className="dashboard__content">
                                {/* VIDEOS */}
                                <div className="dashboard__videos">
                                    {this.state.loading ? this.buildLoading('video') : videos}
                                </div>
                            
                            </div>
                        </PerfectScrollbar>
                    }

                    {/* Fixed button to show sidebar on mobile*/}
                    { isTablet && 
                        <button className="dashboard__slider-button" onClick={this.showSidebar}>
                            <IconSlider/>
                        </button>
                    }
                </div>
            </div>
        );
    }
}

export default Dashboard;