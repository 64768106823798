import React from 'react';

// Init component.
function IconInfo(props) {
	return (
        <svg className="icon__info" width="15" height="16" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M7.5 14.5C11.0899 14.5 14 11.5899 14 8C14 4.41015 11.0899 1.5 7.5 1.5C3.91015 1.5 1 4.41015 1 8C1 11.5899 3.91015 14.5 7.5 14.5Z" stroke="#8389A9" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M7.5 10.6V8" stroke="#8389A9" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M7.5 5.39999H7.506" stroke="#8389A9" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
        
    );
}

export default IconInfo;