import React from 'react';

// Init component.
function IconTwitter(props) {
	return (
        <svg className="icon__twitter" width="14" height="12" viewBox="0 0 14 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M14 0.00639909C13.3906 0.436248 12.7159 0.765014 12.0018 0.980036C11.6186 0.539361 11.1092 0.227021 10.5427 0.0852612C9.9761 -0.0564989 9.37968 -0.0208401 8.83405 0.187415C8.28842 0.39567 7.81992 0.766473 7.4919 1.24967C7.16388 1.73287 6.99217 2.30516 7 2.88913V3.52549C5.88168 3.55449 4.77354 3.30646 3.77428 2.8035C2.77502 2.30054 1.91566 1.55826 1.27273 0.642763C1.27273 0.642763 -1.27273 6.37004 4.45455 8.91549C3.14397 9.80511 1.58274 10.2512 0 10.1882C5.72727 13.37 12.7273 10.1882 12.7273 2.87004C12.7267 2.69278 12.7096 2.51596 12.6764 2.34185C13.3258 1.70135 13.7842 0.892671 14 0.00639909Z" fill="#707486"/>
        </svg>
    )
}

export default IconTwitter;